/**
 *
 * ApplicationLayout
 *
 */

import { includes } from 'lodash';
import React, { memo, useContext } from 'react';
import SideNavigation from 'components/base/SideNavigation';
import { TopNavBar } from 'components/base/TopNavBar';
import { Outlet, useLocation } from 'react-router';
import AuthContext from 'contexts/AuthContext';
import { Loading } from 'components/base/Loading';
import { ActionProvider } from 'contexts/ActionContext';
import classNames from 'classnames';

export function ApplicationLayout() {
  const { isAuthenticated, isAuthenticating } = useContext(AuthContext);
  const location = useLocation();
  const hideNavigation = includes(
    [
      '/panel/decline-interview',
      '/panel/propose-new-time',
      '/panel/interviewer-dashboard',
      '/panel/accept-proposed-time',
      '/panel/calendar',
      '/panel/decline-proposed-time',
      '/panel/join-interview',
      '/panel/join-drive',
      '/job-description',
      '/organizer/reschedule',
      '/organizer/change-interviewer',
      '/dashboard/pre-interview',
      '/scheduling/slot-booking',
    ],
    location.pathname,
  );

  return (
    <ActionProvider>
      <div className="flex h-full relative overflow-hidden">
        {isAuthenticated && !hideNavigation && (
          <div className="absolute z-50 h-full">
            <SideNavigation />
          </div>
        )}
        <div className={classNames('flex flex-1 flex-col overflow-hidden', !hideNavigation && 'ml-[70px]')}>
          {isAuthenticated && !hideNavigation && <TopNavBar />}
          {isAuthenticating ? <Loading /> : <Outlet />}
        </div>
      </div>
    </ActionProvider>
  );
}

ApplicationLayout.propTypes = {};

export default memo(ApplicationLayout);
